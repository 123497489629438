import { Atom, state } from ":mods";
import { TUTORS_FORM_STEPS } from "../../const";
import { createTalkInfoInputs } from "../../form/tutors/talk-info";
import { FormPageProps } from "../../model";
import { Show, createEffect, createSignal } from "solid-js";
import { getAvalaibleTutorDates } from "../../api";

export function TalkInfoPage({ onFormSubmit }: FormPageProps) {
  const [selectedTimes, setSelectedTimes] = createSignal([]);
  const $datesList = state.create([]);
  const $db_dates = state.createAsync(getAvalaibleTutorDates);
  createEffect(() => {
    if ($db_dates.value) {
      const dates = $db_dates.value?.data?.[0]?.data;
      $datesList.set(dates);
    }
  });
  const FormInputs = createTalkInfoInputs();
  const onTimeSelected = ({ currentTarget }) => {
    const value = parseInt(currentTarget.id);
    if (selectedTimes().find((time) => time == value)) {
      setSelectedTimes(selectedTimes().filter((time) => time != value));
      return;
    }
    setSelectedTimes([...selectedTimes(), value]);
  };
  const onSubmit = async (e: Event) => {
    e.preventDefault();
    // if (!FormInputs.Actions.canSubmit()) return;
    const values = {
      ...FormInputs.Actions.getValuesSnakecase(),
      ava_regions_dates: selectedTimes() as any,
    };
    await onFormSubmit(values);
  };

  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
      <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={7} />
      <h1 class="text-28px font-medium my-40px w-630px ">Can you present a case study?</h1>
      <form class="flex flex-col items-left gap-20px text-16px pt-40px w-630px">
        <div class="flex flex-col gap-10px">
          <FormInputs.PresentCaseInPerson.Label
            title="Do you have a project that you can present as a relevant inspirational case study?"
            class="mb-10px"
          >
            <FormInputs.PresentCaseInPerson.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.PresentCaseInPerson.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.PresentCaseInPerson.Input
              type="radio"
              class=""
              placeholder=""
              name="have_case_study"
              id="true"
            />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.PresentCaseInPerson.Input
              type="radio"
              class=""
              placeholder=""
              name="have_case_study"
              id="false"
            />
            <p class="text-16px">No</p>
          </div>
        </div>
        <Show when={(FormInputs.PresentCaseInPerson.control.value as string) == "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.ProjectDescription.Label title="Can you describe the project?" class="mb-10px">
              <FormInputs.ProjectDescription.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.ProjectDescription.Label>
            <div class="border-2 border#p border-solid w-630px">
              <FormInputs.ProjectDescription.TextArea class="textarea-resize" placeholder="" />
            </div>
          </div>
        </Show>
        <Show when={(FormInputs.PresentCaseInPerson.control.value as string) == "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.ProjectDescriptionMoreDetails.Label
              title="Please share any video or article links that you have in order for us to be able to see and learn more about the project"
              class="mb-10px"
            >
              <FormInputs.ProjectDescriptionMoreDetails.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.ProjectDescriptionMoreDetails.Label>
            <div class="border-2 border#p border-solid w-630px">
              <FormInputs.ProjectDescriptionMoreDetails.TextArea class="textarea-resize" placeholder="" />
            </div>
          </div>
        </Show>

        <Show when={$datesList.value}>
          <label class="mb-10px">
            Please select the regions / dates that you would be available
            {/* <FormInputs.AvaRegionsDates.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" /> */}
          </label>
          <div class="flex gap-20px w-630px flex-wrap">
            {$datesList.value?.map((time) => {
              return (
                <div class="flex flex-col gap-10px w-305px">
                  <div class="flex items-center gap-10px border-2 border#p border-solid text-16px px-2.5 py-2">
                    <input
                      type="checkbox"
                      class="w-23px h-23px cursor-pointer"
                      placeholder=""
                      name="availablity"
                      id={`${time.id}`}
                      onClick={onTimeSelected}
                    />
                    <p class="text-16px">{time.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Show>
        <div class="flex justify-end">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-95px h-44px text#n-50 text-16px mt-20px mb-80px px-30px py-15px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
            events={{
              onSetButtonEnabledState: (enable_btn) => {
                return enable_btn && selectedTimes().length > 0;
              },
            }}
          />
        </div>
      </form>
    </section>
  );
}
